export default class StatusMessage {
    /**
     * Set up the constructor
     * @param {String} message The text of the status message
     * @param {String} type The type of status message to display ['notice'|'error']
     * @param {Object} options  User options
     */
    constructor(message, type = 'notice', options) {
        // Make sure there's a message defined
        if (!message) return;

        // Merge user options into defaults
        this.settings = Object.assign({}, this.defaults, options);

        // Get the status message container
        this.container = document.querySelector(this.settings.container);

        // Display the status message
        this.displayMessage(message, type, this.container, this.settings);
    }

    // Variables
    container;
    duration;
    settings;
    effectClass;

    defaults = {
        container: '#notifications',
        duration: 5000,
        effectClass: 'closed'
    };

    // Methods
    displayMessage(message, type, container, settings) {
        let note = document.createElement('div');
        note.classList.add(type);
        if (type === 'error') {
            note.innerHTML = '<svg class="fill-current inline-block h-4 w-4 mr-2 relative"><use xlink:href="#icon-alert"></use></svg>';
        }
        note.appendChild(document.createTextNode(message));

        container.appendChild(note);

        window.setTimeout(() => {
            note.classList.add(settings.effectClass);
            window.setTimeout(() => {
                container.removeChild(note)
            }, 300);
            // this.regionEl.removeChild(note)
        }, settings.duration);
    }

    /**
     * Destroy the current initialization
     */
    destroy() {
        // Make sure plugin has been initialized
        if (!this.settings) return;

        // Remove the status messages
        this.container.innerHTML = '';

        // Reset variables
        this.settings = null;
        this.container = null;
        this.duration = null;
        this.effectClass = null;
    }
}